import { CgDistributeHorizontal } from "react-icons/cg";
import Burger from "../Burger/Burger";
import MobileMenu from "../MobileMenu/MobileMenu";
import logo from "./__.svg";
import "./Header.css";

const Header = ({ isOpenMenu, setIsOpenMenu }) => {
  const handleToggleMenu = () => {
    setIsOpenMenu((prevState) => !prevState);
  };

  return (
    <header className="header">
      <div className="container container--header">
        <div className="header__wrapper">
          <div className="header__left">
            <div className="header__left-logo-wrapper">
              <a href="#promo" className="header__left-logo">
                <img src={logo} alt="logo" />
                {/* <p className="header__left-logo-text">Южураладвокатцентр</p> */}
              </a>
            </div>

            <ul className="header__left-list">
              <li className="header__left-item">
                <a href="#heritage" className="header__left-link">
                  Важные вопросы
                </a>
              </li>
              <li className="header__left-item">
                <CgDistributeHorizontal />
              </li>

              <li className="header__left-item">
                <a href="#our-approach" className="header__left-link">
                  Наш подход
                </a>
              </li>
              <li className="header__left-item">
                <CgDistributeHorizontal />
              </li>

              <li className="header__left-item">
                <a href="#consultation" className="header__left-link">
                  Оставить заявку
                </a>
              </li>
              <li className="header__left-item">
                <CgDistributeHorizontal />
              </li>

              <li className="header__left-item">
                <a href="#contacts" className="header__left-link">
                  Обратная связь
                </a>
              </li>
            </ul>
          </div>

          <Burger isOpenMenu={isOpenMenu} handleToggleMenu={handleToggleMenu} />
        </div>
      </div>
      <MobileMenu isOpenMenu={isOpenMenu} handleToggleMenu={handleToggleMenu} />
    </header>
  );
};
export default Header;
