import { PiSuitcaseSimpleLight } from "react-icons/pi";
import { IoChatbubblesOutline } from "react-icons/io5";
import { TbReportAnalytics } from "react-icons/tb";
import { IoCreateOutline } from "react-icons/io5";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { HiBuildingLibrary } from "react-icons/hi2";

import "./How.css";

const How = () => {
  return (
    <section className="how" id="our-approach">
      <div className="container">
        <div className="how__inner">
          <div className="how__wrapper">
            <h2 className="how__title">Подход к решению вашей проблемы:</h2>
            <div className="how__row ">
              <div className="how__items">
                <div className="how__item">
                  <IoChatbubblesOutline />
                  <p className="how__item-text">
                    Консультация специалиста по праву или адвоката-партнера
                  </p>
                </div>
                <div className="how__item">
                  <TbReportAnalytics />
                  <p className="how__item-text">
                    Анализ обстановки с оценкой перспектив в суде
                  </p>
                </div>
                <div className="how__item">
                  <IoCreateOutline />
                  <p className="how__item-text">
                    Разработка правовой стратегии и плана действий
                  </p>
                </div>
                <div className="how__item">
                  <HiOutlineDocumentText />
                  <p className="how__item-text">
                    Подготовка и представление документов в суде
                  </p>
                </div>
                <div className="how__item">
                  <PiSuitcaseSimpleLight />
                  <p className="how__item-text">
                    Участие в переговорах для внесудебного урегулирования споров
                  </p>
                </div>
                <div className="how__item">
                  <HiBuildingLibrary />
                  <p className="how__item-text">
                    Представление в судебном процессе
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default How;

