import "./Can.css";

const Can = () => {
  return (
    <section className="can">
      <div className="can__inner">
        <div className="container">
          <div className="can__wrapper">
            <h2 className="can__title">Как обманывают недобросовестные брокеры?</h2>
            <div className="can__items-top">
              <div className="can__item-top">
                <span className="can__item-top-number">01</span>
                <h3 className="can__item-top-title">Агрессивные продажи:</h3>

                <p className="can__item-top-text">
                Они могут применять высокое давление на клиентов, чтобы заставить их делать новые вложения, обещая высокую доходность.
                </p>
              </div>
              <div className="can__item-top">
              <span className="can__item-top-number">02</span>

                <h3 className="can__item-top-title">
                Обещания щедрых условий:
                </h3>

                <p className="can__item-top-text">
                Брокеры могут предлагать чрезмерно выгодные условия, но на самом деле условия таковы, что выгода зависит от суммы вложения.
                </p>
              </div>
              <div className="can__item-top">
              <span className="can__item-top-number">03</span>

                <h3 className="can__item-top-title">Неотдача средств:</h3>

                <p className="can__item-top-text">
                Они могут отказывать в выводе средств и даже исчезать, меняя название компании, оставляя клиентов без своих денег.
                </p>
              </div>
              <div className="can__item-top">
              <span className="can__item-top-number">04</span>

                <h3 className="can__item-top-title">Блокировка вывода средств без объяснений:</h3>

                <p className="can__item-top-text">
                Брокеры могут предъявлять необоснованные причины для блокировки вывода денег.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Can;
