import { Link } from "react-router-dom";
import { FaHandPointLeft } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__bottom">
          <p className="footer__bottom-text">
            Оставляя на сайте, размещенном по веб-адресу: argentabank.ru  (далее
            – Сайт), принадлежащем ООО «ЮЖНО-УРАЛЬСКИЙ АДВОКАТСКИЙ ЦЕНТР"
            ЧЕЛЯБИНСКОЙ ОБЛАСТИ», ИНН 7452036252, оператор персональных данных
            рег.№ 1 (далее — Администрация Сайта), любые из Ваших данных
            (которые отнесены к категории персональных в соответствии с ФЗ «О
            персональных данных» от 27.07.2006 №152-ФЗ), путем заполнения полей
            любых форм на Сайте, Вы автоматически присоединяетесь к настоящему
            Соглашению, приобретаете статус пользователя Сайта
            (далее-Пользователь Сайта) и соглашаетесь со всеми без исключения
            его условиями. .
          </p>
          <p className="footer__bottom-text">
            Реквизиты Общество с ограниченной ответственностью «ЮЖНО-УРАЛЬСКИЙ
            АДВОКАТСКИЙ ЦЕНТР" ЧЕЛЯБИНСКОЙ ОБЛАСТИ» Юридический и фактический
            адрес: 454007, Челябинская область, город Челябинск, пр-кт Ленина,
            д.24, кв.12
            <br />
            <span>ИНН: 7452036252 </span>
            <span>КПП: 745201001 </span>
            <span>ОКПО: 34538113 </span>
            <span>ОГРН: 1037403882570 </span>
          </p>
          <div className="footer__bottom-links">
            <a href="tel:+79690745855" className="footer__link-phone">
              +7 (969) 074-58-55
            </a>
            <a href="mailto:info@argentabank.ru" className="footer__link-email">
              info@argentabank.ru
            </a>
          </div>

          <div className="footer__bottom-privacy">
            <Link to="/privacy" className="footer__bottom-link">
              Политика конфиденциальности
              <FaHandPointLeft className="footer__bottom-icon" />
            </Link>
            <Link to="/politic" className="footer__bottom-link">
              Соглашение на обработку данных
              <FaHandPointLeft className="footer__bottom-icon" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
