import Header from "../Header/Header";
import Form from "../Form/Form";
import "./Promo.css";

const Promo = ({ isOpenMenu, setIsOpenMenu }) => {
  return (
    <section className="promo" id="promo">
      <div className="inner">
        <Header isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
        <div className="container ">
          <div
            style={{
              display: isOpenMenu ? "none" : "flex",
            }}
            className="promo__wrapper"
          >
            <div className="promo__content-wrapper">
              <span className="promo__under-header">
                Обманула брокерская компания или брокер?
              </span>
              <h1 className="promo__heading"><span>Юридическая компания </span> <br/> «ЮЖНО-УРАЛЬСКИЙ АДВОКАТСКИЙ ЦЕНТР» </h1>
              <p className="promo__text-main">
                Мы стремимся помочь нашим клиентам возвращать утраченные
                средства и упорядочивать правовые аспекты их деятельности в
                случае финансового мошенничества.
              </p>
              <ul className="promo__list">
                <li className="promo__item">
                  <p className="promo__item-text">
                    Участие в досудебном и судебном урегулировании
                  </p>
                </li>
                <li className="promo__item">
                  <p className="promo__item-text">
                    Возврат Ваших денег законным способом
                  </p>
                </li>
                <li className="promo__item">
                  <p className="promo__item-text">
                    Выработка правовой позиции и подготовка доказательств
                  </p>
                </li>
                <li className="promo__item">
                  <p className="promo__item-text">
                    Другие услуги юриста по возврату инвестициям
                  </p>
                </li>
              </ul>
            </div>
            <div className="promo__form-wrapper">
              <Form />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Promo;
