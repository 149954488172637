import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import Button from "../UI/Button";
import "./Form.css";


const API_URL = "./api.php";

const Form = () => {
  const navigate = useNavigate();

  const [validationErrors, setValidationErrors] = useState({
    phone: "",
    name: "",
    checkbox: "",
  });
  const phonePattern = /^\+?\d+$/;
  const maxPhoneLength = 12;
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [errorTimeout, setErrorTimeout] = useState(null);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (errorTimeout) {
      clearTimeout(errorTimeout);
    }
    setErrorTimeout(
      setTimeout(() => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          name: "",
          phone: "",
          checkbox: "",
        }));
      }, 5000)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, phoneNumber, isChecked]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    const enteredName = formData.get("name");
    const enteredPhoneNumber = formData.get("phoneNumber");
    const checboxValue = formData.get("checkbox");

    if (!enteredName  || !enteredPhoneNumber) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        name: !enteredName ? "Имя не может быть пустым" : "",
        phone: !enteredPhoneNumber ? "Номер телефона не может быть пустым" : "",
        checkbox: !checboxValue ? "Вы должны согласиться с условиями" : "",
      }));
      return;
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        name: "",
        phone: "",
      }));
    }

    // check name
    if (enteredName.trim().length < 3) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        name: "Имя должно содержать не менее трех символов",
      }));
      return;
    } else if (
      !/^\s*[a-zA-Zа-яА-Я]+(?:\s[a-zA-Zа-яА-Я]+)?\s*$/.test(enteredName)
    ) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        name: "Имя должно содержать только буквы",
      }));
      return;
    }

    // check phone
    if (!phonePattern.test(enteredPhoneNumber)) {
      if (enteredPhoneNumber.length < 8) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          phone: "Введите корректный номер телефона",
        }));
        return;
      }
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Введите корректный номер телефона",
      }));
      return;
    }

    //check checkbox
    if (!isChecked) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        checkbox: "Вы должны согласиться с условиями",
      }));
      return;
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        checkbox: "", 
      }));
    }

    const formDataToSend = {
      name: enteredName,
      phone: enteredPhoneNumber,
      form: 'top-form',
    };
    console.log(formDataToSend);

    fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataToSend),
    })
      .then((data) => {
          console.log(data.success);
          navigate("/thank-you");
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    console.log("Form data:", formDataToSend);
    console.log("Form submitted successfully");

    setName("");
    setPhoneNumber("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      if (/^[a-zA-Zа-яА-Я\s]*$/.test(value)) {
        setName(value);
      }
  
    } else if (name === "phoneNumber") {
      // Разрешаем вводить только цифры и символ "+"
      if (/^[\d+]*$/.test(value)) {
        setPhoneNumber(value);
      }
    }
  };

  return (
    <form id="form" className="form" onSubmit={handleSubmit}>
      <h2 className="form__heading">
        Бесплатная консультация <br /> по телефону
      </h2>
      <p className="form__text">
        Мы перезвоним вам  <span>в ближайшее время</span>
      </p>
      <div className="form__group">
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Ваше имя и фамилия"
          value={name}
          onChange={handleChange}
        />
        <span className="error">{validationErrors.name}</span>
      </div>

      <div className="form__group">
        <input
          type="text"
          name="phoneNumber"
          placeholder="Ваш номер телефона"
          value={phoneNumber}
          onChange={handleChange}
          maxLength={maxPhoneLength}
        />
        <span className="error">{validationErrors.phone}</span>
      </div>
      <label className="form__checkbox-label">
        <input
          className="form__checkbox"
          name="checkbox"
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        Я согласен(а) на обработку
          <Link to="/privacy" className="form__link">персональных данных</Link> 
      </label>
      <span className="error">{validationErrors.checkbox}</span>
      <div className="button main__button form__button">
        <Button text="Получить консультацию" />
      </div>

      <div className="form__footer">
        <p className="form__footer-text">Или свяжитесь с нами самостоятельно</p>
        <a href="tel:+79690745855" className=" form__footer-link">
          {" "}
          +7 (969) 074-58-55
        </a>
      </div>
    </form>
  );
};

export default Form;
