import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {CardActionArea} from "@mui/material";
import img1 from './11.webp'
import img2 from './22.webp'

import "./Return.css";

const Return = () => {
  return (
    <div className="return">
      <div className="container">
        <div className="return__wrapper">
          <h2 className="return__heading">Как мы возвращаем деньги</h2>
          <p className="return__text">
            В зависимости от ситуации подберем наиболее эффективный способ возврата
            инвестиций
          </p>
          <ul className="return__list">
            <li className="return__item">
              <Card sx={{
                maxWidth: 345
              }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={img1}
                    alt="organs"/>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" className="return__item-title">
                      Правоохранительные органы
                    </Typography>
                    <Typography variant="body2" color="text">
                      Адвокат поможет признать действия брокера мошенническими и обратится к
                      российским инстанциям для возврата денег через суд.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </li>
            <li className="return__item">
              <Card sx={{
                maxWidth: 345
              }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="178"
                    image={img2}
                    alt="reserve"/>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" className="return__item-title">
                      Процедура
                      возврата
                    </Typography>
                    <Typography variant="body2" color="text">
                      Процедура, с помощью которой можно оспорить банковский перевод денег в случае,
                      если контрагент оказался недобросовестным.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Return;
