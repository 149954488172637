import './MobileMenu.css';

const MobileMenu = ({isOpenMenu, handleToggleMenu}) => {
  return (
    <div className={`mobile-menu ${isOpenMenu
      ? 'active'
      : ''}`}>
      <div style={{display: isOpenMenu ? 'flex' : 'none'}} className="mobile-menu__wrapper">
        <ul className="mobile-menu__list">
          <li onClick={handleToggleMenu} className="mobile-menu__item">
            <a href="#heritage" className="mobile-menu__link">Наше наследие</a>
          </li>
          <li onClick={handleToggleMenu} className="mobile-menu__item">
            <a href="#our-approach" className="mobile-menu__link">Наш подход</a>
          </li>
          <li onClick={handleToggleMenu} className="mobile-menu__item">
            <a href="#consultation" className="mobile-menu__link">Оставить заявку</a>
          </li>
          <li onClick={handleToggleMenu} className="mobile-menu__item">
            <a href="#contacts" className="mobile-menu__link">Обратная связь</a>
          </li>
        </ul>
      </div>
    </div>
  )
}
export default MobileMenu