import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { CiLocationArrow1 } from "react-icons/ci";
import "./Accordion.css";

export default function LegalAccordion() {
  const [expanded, setExpanded] = React.useState(true);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        // slots={{ transition: Fade }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
          "& .MuiAccordionDetails-root": {
            display: expanded ? "block" : "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<CiLocationArrow1 />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography className="accordion-title">
              Процесс разбора случаев мошенничества
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordion-text">
            Мы предоставляем детальную консультацию и оказываем помощь в разборе
            случаев мошенничества, помогая вам восстановить ущерб и защитить
            свои права.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<CiLocationArrow1 />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography className="accordion-title">
              Правовые меры по защите клиентов от мошенничества
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordion-text">
            У нас есть эксперты, которые специализируются на разработке правовых
            стратегий и мер по защите клиентов от мошенничества.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<CiLocationArrow1 />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography className="accordion-title">
              Процесс взыскания ущерба от мошенников
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordion-text">
            Мы предоставляем помощь в процессе взыскания ущерба от мошенников
            через судебные и внесудебные механизмы.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<CiLocationArrow1 />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          <Typography className="accordion-title">
              Помощь в предотвращении мошенничества в будущем
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordion-text">
            Мы также предоставляем рекомендации и помощь в предотвращении
            мошенничества в будущем.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<CiLocationArrow1 />}
          aria-controls="panel5-content"
          id="panel5-header"
        >
          <Typography  className="accordion-title">
              Юридическое сопровождение в судебных процессах
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordion-text">
            Мы обеспечиваем полное юридическое сопровождение на всех этапах
            судебного процесса в случае мошенничества.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<CiLocationArrow1 />}
          aria-controls="panel6-content"
          id="panel6-header"
        >
          <Typography className="accordion-title">
            Оптимизация правовой защиты
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordion-text">
              Мы постоянно совершенствуем наши методы работы и стратегии, чтобы
              обеспечить оптимальную правовую защиту наших клиентов от
              мошенничества.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

