import './Thanks.css'

const Thanks = () => {
  return (
    <div className="body">
    <div className="box">
      <div className="checkmark"></div>
      <h1>СПАСИБО!</h1>
      <p>Ваша заявка успешно отправлена. Мы свяжемся с вами в ближайшее время.</p>
    </div>
    </div>
  )
}
export default Thanks