import { RiPriceTag3Line } from "react-icons/ri";
import { CiTimer } from "react-icons/ci";
import { CiPercent } from "react-icons/ci";
import { PiCurrencyRubLight } from "react-icons/pi";
import Form2 from "../Form2/Form2";
import "./Free.css";

const Free = () => {
  return (
    <section className="free" id="consultation">
      <div className="container">
        <div className="free__wrapper">
          <h2 className="free__heading">
            <span>Бесплатная</span> консультация от наших специалистов
          </h2>
          <p className="free__text-heading">
            Всего через минуту вы получите юридическую консультацию и анализ
            вашей ситуации
          </p>
          <div className="free__row">
            <div className="free__text">
              <ul className="free__text-list">
                <li className="free__text-item">
                  <div className="free__text-item-img">
                    <RiPriceTag3Line />
                  </div>
                  <div className="free__text-item-text-wrapper">
                    <p className="free__text-item-title">
                      Стоимость консультации:
                    </p>
                    <p className="free__text-item-text">
                      <span className="red">3600₽</span>
                      <span className="green">Бесплатно</span>
                    </p>
                  </div>
                </li>

                <li className="free__text-item">
                  <div className="free__text-item-img">
                    <CiTimer />
                  </div>
                  <div className="free__text-item-text-wrapper">
                    <p className="free__text-item-title">
                      Время консультации:
                    </p>
                    <p className="free__text-item-text">
                      Перезвоним:&nbsp;&nbsp;
                      <span className="green">в ближайшее время</span>
                    </p>
                  </div>
                </li>

                <li className="free__text-item">
                  <div className="free__text-item-img">
                    <CiPercent />
                  </div>
                  <div className="free__text-item-text-wrapper">
                    <p className="free__text-item-title">Акция:</p>
                    <p className="free__text-item-text">
                      Скидка до&nbsp;<span className="green"> 20%</span>
                    </p>
                  </div>
                </li>

                <li className="free__text-item">
                  <div className="free__text-item-img">
                    <PiCurrencyRubLight />
                  </div>
                  <div className="free__text-item-text-wrapper">
                    <p className="free__text-item-title">Цена услуги:</p>
                    <p className="free__text-item-text">
                      <span className="green">от 2900 ₽</span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="free__form">
              <Form2/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Free;
