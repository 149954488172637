import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ThankYouPage from "./Thanks";
import Heritage from "../Heritage/Heritage";
import Promo from "../Promo/Promo";
import Can from "../Can-help/Can";
import Free from "../Free/Free";
import How from "../How/How";
import Pre from "../PreFooter/Pre";
import Footer from "../Footer/Footer";
import Gap from "../Gap/Gap";
import Reviews from "../Reviews/Reviews";
import Contacts from '../Contacts/Contacts'
import Questions from "../Questions/Questions";
import Return from "../Return/Return";
import Privacy from "../Privacy/Privacy";
import Politic from "../Politic/Politic";
import "./App.css";

function App() {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  return (
    <Router>
      <Routes>
        <Route
          path="*"
          element={
            <div className="App">
              <Promo isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
              <main
                style={{ display: isOpenMenu ? "none" : "block" }}
                className="main"
              >
                <Heritage />
                <How />
                <Free />
                <Can />
                <Return/>
                <Reviews />
                <Gap />
                <Pre />
                <Contacts />
                <Questions/>
              </main>
              <Footer />
            </div>
          }
        />
        <Route path="/politic" element={<Politic />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
      </Routes>
    </Router>
  );
}

export default App;
