import { RiTeamLine } from "react-icons/ri";
import { GiLayeredArmor } from "react-icons/gi";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { CiBank } from "react-icons/ci";

import { MdPhoneInTalk } from "react-icons/md";
import "./Gap.css";


const Gap = () => {
  return (
    <div className="gap">
      <div className="container">
        <div className="gap__wrapper">
          <div className="gap__top">
            <ul className="gap__top-list">
              <li className="gap__top-item">
                <div className="gap__top-item-img-wrapper">
                  <RiTeamLine />
                </div>
                <h3 className="gap__top-item-title">Команда профессионалов</h3>
                <p className="gap__top-item-text">Команда квалифицированных специалистов</p>
              </li>

              <li className="gap__top-item">
                <div className="gap__top-item-img-wrapper">
                  <GiLayeredArmor />
                </div>
                <h3 className="gap__top-item-title">
                  Детальное изучение
                </h3>
                <p className="gap__top-item-text">
                  Детально изучим Вашу ситуацию и дадим честный прогноз дела
                </p>
              </li>

              <li className="gap__top-item">
                <div className="gap__top-item-img-wrapper">
                  <HiOutlineDocumentText />
                </div>
                <h3 className="gap__top-item-title">
                  Оплата поэтапная по договору
                </h3>
                <p className="gap__top-item-text">
                  Прописываем в договоре и фиксируем стоимость
                </p>
              </li>

              <li className="gap__top-item">
                <div className="gap__top-item-img-wrapper">
                  <CiBank />
                </div>
                <h3 className="gap__top-item-title">
                  Нет ограничений на судебных слушаниях.
                </h3>
                <p className="gap__top-item-text">
                  Обеспечиваем полное сопровождение вашего дела от первого
                  обращения до успешного результата.
                </p>
              </li>
            </ul>
          </div>
          <div className="gap__bottom">
            <div className="gap__bottom-wrapper">
              <h3 className="gap__bottom-title">
                В 65% случаев брокеры вернут ваши деньги до обращения в суд
              </h3>
              <p className="gap__bottom-text">
                Продавцу будет проще принять ваши условия, иначе, вы сможете
                требовать дополнительные компенсации через суд
              </p>
              <div className="gap__bottom-img-wrapper">
                <MdPhoneInTalk/>
              </div>
              <p className="gap__bottom-link-text">
                Получите бесплатную консультацию юриста по вопросу возврата денежых средств
              </p>
              <a href="tel:+79690745855" className="gap__bottom-link"> +7 (969) 074-58-55</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Gap;
