import Link from "../UI/Link";
import "./Pre.css";

const Pre = () => {
  return (
    <section className="pre" id="get">
      <div className="pre__inner">
        <div className="container">
          <div className="pre__wrapper">
            <div className="pre__row">
              <div className="pre__right">
                <div className="pre__right-wrapper">
                  <p className="pre__right-text-main">
                    Получи бесплатную консультацию от наших юристов
                  </p>
                  <p className="pre__right-text">
                  Мы организуем подбор экспертных юридических фирм, специализирующихся на возврате утраченных средств и компенсации убытков в разнообразных финансовых обстоятельствах. 
                  Наш подход индивидуален: в зависимости от уникальных требований вашего дела, мы отбираем адвокатов с наиболее подходящей экспертизой и проверенным опытом в возврате средств, включая дела с высокой степенью сложности.
                  </p>
                  <div className=" pre__right-btn">
                    <Link href="#consultation" text="Записаться на консультацию" />
                  </div>
                </div>
              </div>
              <div className="pre__left">
                <p className="pre__left-title">
                  Мы предоставляем комплексную помощь в случаях финансового
                  мошенничества:
                </p>
                <p className="pre__left-text">
                  Оказываем консультации по вопросам защиты и блокировки личного
                  пользовательского интерфейса;
                </p>
                <p className="pre__left-text">
                  Информируем о схемах мошенничества, предупреждаем о рисках и
                  способах предотвращения потерь;
                </p>
                <p className="pre__left-text">
                  Занимаемся расследованиями по сомнительным транзакциям,
                  предоставляем результаты наших исследований;
                </p>
                <p className="pre__left-text">
                  Готовим и подаём официальные заявления в правоохранительные
                  органы для принятия мер против мошенников;
                </p>
                <p className="pre__left-text">
                  Запрашиваем информацию о владельцах и администрации сайтов,
                  через которые было совершено мошенничество;
                </p>
                <p className="pre__left-text">
                  Формулируем и направляем официальные претензии к незаконным
                  обладателям ваших финансовых средств или активов.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Pre;
