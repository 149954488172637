import { AiOutlineNodeIndex } from "react-icons/ai";
import { MdPlace } from "react-icons/md";
import { IoPhonePortraitOutline } from "react-icons/io5";
import "./Contacts.css";

const Contacts = () => {
  return (
    <section className="contacts" id="contacts">
      <div className="container">
        <div className="contact-info">
          <div className="contact-info-items">
            <div className="contact-info-item">
              <p className="contact-info-item-title-icon">
                <AiOutlineNodeIndex />
              </p>
              <p className="contact-info-item-title">Индекс:</p>
              <p className="contact-info-item-text"> 454007</p>
            </div>

            <div className="contact-info-item">
              <p className="contact-info-item-title-icon">
                <MdPlace />
              </p>
              <p className="contact-info-item-title">
                Юридический и фактический адресс:
              </p>
              <p className="contact-info-item-text">
                Челябинская область, город Челябинск, пр-кт Ленина, д.24, кв.12
              </p>
            </div>

            <div className="contact-info-item">
              <p className="contact-info-item-title-icon">
                <IoPhonePortraitOutline />
              </p>
              <p className="contact-info-item-title">Контактный номер:</p>
              <p className="contact-info-item-text">
                <a
                  className="contact-info-item-text contact-info-item-link"
                  href="tel:+79690745855"
                >
                  +7 (969) 074-58-55
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Contacts;
