import LegalAccordion from "../Accordion/Accordion";
import "./Heritage.css";

const Heritage = () => {
  return (
    <section className="heritage" id="heritage">
      <div className="container">
        <div className="heritage__wrapper">
          <div className="heritage__acc-wrapper">
            <h2 className="heritage__acc-heading"> Важные вопросы</h2>
            <div className="heritage__acc">
              <LegalAccordion />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Heritage;
