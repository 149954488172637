import "./Questions.css";
const Questions = () => {
  return (
    <section className="questions">
      <div className="container">
        <div className="questions__wrapper">
          <h2 className="questions__heading">
            <span>Остались вопросы?</span> Свяжитесь с нами
          </h2>
          <p className="questions__text">
            Обращение к опытному юристу вовремя существенно увеличивает шансы на
            полное возмещение средств!
          </p>
          <a href="tel:+79690745855" className="questions__link blinking-green">+7 (969) 074-58-55</a>
        </div>
      </div>
    </section>
  );
};
export default Questions;
