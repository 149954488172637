import "./Privacy.css";

const Privacy = () => {
  return (
    <div className="privacy-policy">
      <h1>Политика конфиденциальности</h1>
      <p>
        Оставляя на сайте, размещенном по веб-адресу:&nbsp;
        argentabank.ru&nbsp;
        (далее – Сайт), принадлежащем ООО «ЮЖНО-УРАЛЬСКИЙ АДВОКАТСКИЙ ЦЕНТР», ИНН
        7452036252, оператор персональных данных рег.№ 1 (далее — Администрация
        Сайта), любые из Ваших данных (которые отнесены к категории персональных
        в соответствии с ФЗ «О персональных данных» от 27.07.2006 №152-ФЗ),
        путем заполнения полей любых форм на Сайте, Вы автоматически
        присоединяетесь к настоящему Соглашению, приобретаете статус
        пользователя Сайта (далее-Пользователь Сайта) и соглашаетесь со всеми
        без исключения его условиями.
      </p>
      <h4>
        <b>Пользователь Сайта:</b>
      </h4>
      <p>
        Подтверждает, что все указанные им в формах регистрации на Сайте данные
        принадлежат лично ему, соответствуют действительности, не искажены.
      </p>
      <p>
        Подтверждает и признает, что им внимательно в полном объеме прочитано
        настоящее Соглашение и условия обработки его персональных данных. Текст
        Соглашения и условия обработки персональных данных ему понятны и он с
        ними согласен;
      </p>
      <p>
        Дает согласие на обработку Администрацией Сайта предоставляемых в
        составе информации персональных данных в целях заключения между ним и
        Сайтом настоящего Соглашения, а также его последующего исполнения;
      </p>
      <p>
        Выражает согласие с условиями обработки персональных данных без оговорок
        и ограничений.
      </p>
      <p>
        В соответствии с ФЗ «О персональных данных» от 27.07.2006 №152-ФЗ
        свободно, своей волей и в своем интересе выражает согласие на сбор,
        анализ, обработку, хранение и использование своих персональных данных
        (ФИО, мобильный телефон, электронный адрес (E-mail), реквизиты
        банковских карт, прочие данные).
      </p>
      <h4>
        <b>
          Согласие пользователя сайта на обработку персональных данных является
          конкретным, информированным и сознательным.
        </b>
      </h4>
      <p>
        Настоящее согласие Пользователя Сайта признается исполненным в простой
        письменной форме.
      </p>
      <h4>
        <b>
          Пользователь сайта предоставляет администрации сайта право
          осуществлять следующие действия (операции) с персональными данными:
        </b>
      </h4>
      <p>
        сбор и накопление; хранение в течение установленных нормативными
        документами сроков хранения отчетности, но не менее трех лет, с момента
        даты прекращения пользования услуг Пользователем Сайта; уточнение
        (обновление, изменение); использование; уничтожение; обезличивание;
        передача по требованию суда, в том числе, третьим лицам, с соблюдением
        мер, обеспечивающих защиту персональных данных от несанкционированного
        доступа.
      </p>
      <p>
        Указанное согласие действует бессрочно с момента предоставления данных и
        может быть отозвано Пользователем Сайта путем подачи заявления
        Администрации Сайта с указанием данных, определенных ст. 14 Федерального
        закона от 27.07.2006 N 152-ФЗ «О персональных данных» (ред. от
        22.02.2017г.).
      </p>
      <p>
        Отзыв согласия на обработку персональных данных может быть осуществлен
        путем направления Пользователем соответствующего распоряжения в простой
        письменной форме на адрес электронной почты{" "}
        <a href="mailto:info@argentabank.ru">info@argentabank.ru</a>.
      </p>
      <p>
        Администрация Сайта не несет ответственности за использование (как
        правомерное, так и неправомерное) третьими лицами Информации,
        размещенной Пользователем Сайта на Сайте, включая её воспроизведение и
        распространение, осуществленные всеми возможными способами.
      </p>
      <p>
        Администрация Сайта в любое время имеет право вносить изменения в
        настоящее Соглашение. При внесении изменений в актуальной редакции
        указывается дата последнего обновления. Новая редакция Соглашения
        вступает в силу с момента ее размещения, если иное не предусмотрено
        новой редакцией Соглашения.
      </p>
      <p>
        К настоящему Соглашению и отношениям между Пользователем Сайта и
        Администрацией Сайта, возникающим в связи с применением Соглашения,
        подлежит применению действующее законодательство Российской Федерации.
      </p>
      <h4>
        <b>Реквизиты</b>
      </h4>
      <p>
        КОЛЛЕГИЯ АДВОКАТОВ "ЮЖНО-УРАЛЬСКИЙ АДВОКАТСКИЙ ЦЕНТР" ЧЕЛЯБИНСКОЙ ОБЛАСТИ
        Юридический и фактический адрес: 454007, Челябинская область, город Челябинск, пр-кт Ленина, д.24, кв.12
      </p>
      <p className="privacy__inn">
        ИНН: 7452036252
        <br />
        КПП: 745201001
        <br />
        ОКПО: 34538113
        <br />
        ОГРН: 1037403882570
      </p>
    </div>
  );
};

export default Privacy;
