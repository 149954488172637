import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Reviews.css";
import img1 from './male1.webp'
import img2 from './_ЕгорМитрофанов.webp'
import img3 from './_КонстантинУручьев.webp'
import img4 from './_РоманКовынев.webp'
import img5 from './female4.webp'
import img6 from './СлаваПехин.webp'

function SimpleSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2, // Показывать 2 слайда на компьютерах
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Настройки для мобильных устройств
        settings: {
          slidesToShow: 1, // Показывать только 1 слайд на мобильных устройствах
          slidesToScroll: 1,
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      <div className="slider-item">
        <h3 className="slider-item-text">
          Спасибо! Спасибо компании за помощь в сложной ситуации с мошенничеством.
          Грамотные и доброжелательные сотрудники помогли мне разобраться в
          процессе банкротства и освободиться от долгов.
        </h3>
        <div className="slider-item-person">
          <div className="slider-item-img-wrapper">
            <img src={img1} alt="Иван Иванов" />
          </div>
          <p className="slider-item-name">Иван Олянин</p>
        </div>
      </div>
      <div className="slider-item">
        <h3 className="slider-item-text">
          Я благодарна за профессиональную помощь "Южно-Уральскому адвокатскому ценрту" в ситуацию с мошенничеством. Они не только
          грамотно провели процедуру, но и предоставили гибкую систему оплаты
          услуг.
        </h3>
        <div className="slider-item-person">
          <div className="slider-item-img-wrapper">
            <img src={img5} alt="Мария Петрова" />
          </div>
          <p className="slider-item-name">Мария Фролова</p>
        </div>
      </div>
      <div className="slider-item">
        <h3 className="slider-item-text">
        Очень хорошая компания. Всё прошло чётко и по плану. Спасибо большое, рекомендую обращаться, превосходные специалисты
        </h3>
        <div className="slider-item-person">
          <div className="slider-item-img-wrapper">
            <img src={img2} alt="Алексей Сидоров" />
          </div>
          <p className="slider-item-name">Алексей Каяфа</p>
        </div>
      </div>

      <div className="slider-item">
        <h3 className="slider-item-text">
          Спасибо компании за профессиональное сопровождение в сложной
          ситуации с мошенничеством. Они предоставили не только
          квалифицированную помощь, но и поддержку на каждом этапе процесса.
        </h3>
        <div className="slider-item-person">
          <div className="slider-item-img-wrapper">
            <img src={img4} alt="Роман Ковынев" />
          </div>
          <p className="slider-item-name">Роман Ковынев</p>
        </div>
      </div>
      <div className="slider-item">
        <h3 className="slider-item-text">
        Большую благодарность выражаю этой замечательной компании. Не пожалел что обратилcя именно к этому адвокатскому центру! Работают очень грамотные юристы, все было выполнено в кратчайшие сроки! Всегда были на связи. Рекомендую данную компанию!
        </h3>
        <div className="slider-item-person">
          <div className="slider-item-img-wrapper">
            <img src={img3} alt="Андрей Кузнецов" />
          </div>
          <p className="slider-item-name">Андрей Кузнецов</p>
        </div>
      </div>
      <div className="slider-item">
        <h3 className="slider-item-text">
          Квалифицированные специалисты, всё разъяснили на бесплатной консультации. Полное сопровождение . Я доволен результатом. Рекомендую компанию.
        </h3>
        <div className="slider-item-person">
          <div className="slider-item-img-wrapper">
            <img src={img6} alt="Наталья Иванова" />
          </div>
          <p className="slider-item-name">Александр Попугай</p>
        </div>
      </div>
    </Slider>
  );
}

const Reviews = () => {
  return (
    <div className="reviews">
        <div className="reviews__wrapper">
          <h2 className="reviews__title">Отзывы</h2>
          <SimpleSlider />
        </div>
    </div>
  );
};
export default Reviews;
